































































.entity {
  position: absolute;
  opacity: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
}
