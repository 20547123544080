
























































































































































.wrapper {
  position: absolute;
  right: 40px;
  bottom: 30px;

  &--full {
    bottom: 55px;
    right: 50px;
  }
}
