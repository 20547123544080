













































































.button {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
}
