


































.wrapper {
  position: absolute;
  right: 0;
  bottom: 40px;
  padding: 10px 12px 10px 34px;
  border-radius: 100px 0 0 100px;
  background-color: transparentize(#1a1a1a, 0.3);
  transform: translateX(calc(100% - 72px));
  will-change: transform;

  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);

  &:hover {
    transform: translateX(0);
    padding-left: 12px;

    .chevron {
      opacity: 0;
      visibility: hidden;
    }
  }

  &--disabled {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .chevron {
    position: absolute;
    left: 18px;
    top: 24px;
    opacity: 0.4;
    margin: -12px 0 0 -12px;
    will-change: transform;
  }
}
